.custom-theme {
  $ka-border-color: #9cabb3;
  $ka-thead-background-color: #eef1f5;
  $ka-thead-color: #032536;
  $ka-row-border-size: 1px !default;
  $ka-thead-cell-padding: 0 20px !default;

  .ka-thead {
    position: relative;
  }
  @import "ka-table/style.scss";

  .ka-paging-page-index,
  .ka-paging-size {
    margin: 1px 5px;
    padding: 0px;
  }
}
