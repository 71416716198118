.store-upload-step-wizard {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.store-upload-step-wizard > :nth-child(2) {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.store-upload-step-wizard > :nth-child(2) > div {
  flex: 1;
  display: flex;
  flex-direction: column;
}
