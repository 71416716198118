.report-form {
  transition: all 1s ease;
}

.report-form-hidden {
  transition: all 1s ease;
  transform: translate(-500%);
}

.report-cover {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
}
